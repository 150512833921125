/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';

import { App } from '@capacitor/app';
import { AlertController, Platform } from '@ionic/angular';
import config from 'capacitor.config';
import { ApiService } from '../services/api.service';
import { Browser, OpenOptions } from '@capacitor/browser';

@Injectable({
  providedIn: 'root',
})
export class Versioning {
  //App
  v_code: any = `1.0.1`;
  v_server: any;
  forceUpdate = 0;
  appName: string = config.appName;
  app: any;
  plt: string;
  store_name: string;
  iosID: string;

  constructor(
    private api: ApiService,
    public platform: Platform,
    private alertController: AlertController
  ) {
    this.platform.ready().then(async (res) => {
      this.plt = res;
      if (res !== 'dom') {
        App.getInfo().then((info) => {
          this.app = info;
          this.appName = this.app.name;
          this.v_code = String(this.app.version);
        });
      }

      if (this.platform.is('ios')) {
        this.store_name = 'AppStore';
      } else {
        this.store_name = 'PlayStore';
      }
    });
  }

  async cekUpdate() {
    const url = 'app-versions';
    const param = {
      order: 'created_at',
      sort: 'DESC',
      limit: 1,
      platform: this.platform.is('ios')
        ? 'ios'
        : this.platform.is('android')
        ? 'android'
        : '',
    };

    await this.api
      .list(url, param)
      .then(async (res) => {
        if (res.data) {
          const response = res.data[0];
          this.v_code = String(this.v_code);
          this.v_server = String(response.version_number);

          const splittedVCode = this.v_code.split('.');
          const splittedVServer = this.v_server.split('.');

          this.forceUpdate = response.force_update;

          if (!this.forceUpdate) {
            if (
              Number(splittedVServer[0]) > Number(splittedVCode[0]) ||
              Number(splittedVServer[1]) > Number(splittedVCode[1]) ||
              Number(splittedVServer[2]) > Number(splittedVCode[2])
            ) {
              if (this.plt !== 'dom') {
                this.alertUpdate();
              }
            }
          } else if (this.forceUpdate) {
            if (
              Number(splittedVServer[0]) > Number(splittedVCode[0]) ||
              Number(splittedVServer[1]) > Number(splittedVCode[1]) ||
              Number(splittedVServer[2]) > Number(splittedVCode[2])
            ) {
              if (this.plt !== 'dom') {
                this.alertForceUpdate();
              }
            }
          }
        }
      })
      .catch((err) => {});
  }

  private async alertUpdate() {
    const alert = await this.alertController.create({
      header: 'Peringatan !',
      subHeader: `Aplikasi ${this.appName} versi terbaru sudah tersedia di ${this.store_name}`,
      message: `Segera update Aplikasi ke versi ${this.v_server} untuk menikmati fitur terbaru`,
      mode: 'ios',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Nanti',
          role: 'cancel',
          handler: (blah) => {},
        },
        {
          text: 'Update',
          handler: () => {
            this.goupdate();
          },
        },
      ],
    });

    await alert.present();
  }

  private async alertForceUpdate() {
    const alert = await this.alertController.create({
      header: 'Peringatan !',
      subHeader: `Aplikasi ${this.appName} anda sudah kadaluarsa`,
      message: `Segera update Aplikasi ke versi ${this.v_server} untuk melanjutkan`,
      mode: 'ios',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Keluar',
          role: 'cancel',
          handler: (blah) => {
            App.exitApp();
          },
        },
        {
          text: 'Update',
          handler: () => {
            this.goupdate();
            App.exitApp();
          },
        },
      ],
    });
    await alert.present();
  }

  private async goupdate() {
    let url = ``;
    if (this.platform.is('ios')) {
      url = `https://apps.apple.com/id/app/apple-store/${this.iosID}`;
    } else if (this.platform.is('android')) {
      url = `https://play.google.com/store/apps/details?id=${config.appId}`;
    }
    const opts: OpenOptions = {
      url,
      windowName: '_system',
    };
    await Browser.open(opts);
  }
}
