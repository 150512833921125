import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { NotificationHelper } from '../helper/notification';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  production: boolean = environment.production;
  constructor(
    private api: ApiService,
    public navCtrl: NavController,
    private notification: NotificationHelper
  ) {}

  public async init() {
    const token = localStorage.getItem('token');
    let isAuth = false;
    if (token) {
      isAuth = true;
    }
    if (!isAuth) {
      this.logout();
    }

    if (isAuth && this.production) {
      this.notification.init();
    }
  }

  public logout() {
    const remember = localStorage.getItem('login');
    localStorage.clear();
    if (remember) {
      localStorage.setItem('login', remember);
    }
    this.navCtrl.navigateRoot(['/login']);
  }

  // async updateToken() {
  //   const token = localStorage.getItem('token_fcm');

  //   const profile = JSON.parse(localStorage.getItem('profile'));
  //   const action = 'auth/user-save-firebase-token';
  //   const param = {};
  //   const body = {
  //     user_id: profile.id,
  //     device_id: localStorage.getItem('uuid'),
  //     token: token ? token : '',
  //   };

  //   await this.api
  //     .post(action, param, body)
  //     .then(async (res) => {
  //       console.log('Respon Update Token : ', res);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  // async deleteToken() {
  //   const token = localStorage.getItem('token_fcm');

  //   const profile = JSON.parse(localStorage.getItem('profile'));
  //   const action = 'auth/user-delete-firebase-token';
  //   const param = {};
  //   const body = {
  //     user_id: profile.id,
  //     device_id: localStorage.getItem('uuid'),
  //     token: token ? token : '',
  //   };

  //   await this.api
  //     .post(action, param, body)
  //     .then(async (res) => {
  //       console.log('Respon delete Token : ', res);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  private async getProfile() {
    return new Promise(async (resolve, reject) => {
      const action = 'me';
      const param = {};
      await this.api
        .get(action, param)
        .then((res) => resolve(true))
        .catch((err) => reject(false));
    });
  }
}
