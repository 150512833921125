import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  // {
  //   path: 'home',
  //   loadChildren: () =>
  //     import('./pages/tabs/home/home.module').then((m) => m.HomePageModule),
  // },
  // {
  //   path: 'inspection',
  //   loadChildren: () =>
  //     import('./pages/tabs/inspection/inspection.module').then(
  //       (m) => m.InspectionPageModule
  //     ),
  // },
  // {
  //   path: 'report',
  //   loadChildren: () =>
  //     import('./pages/tabs/report/report.module').then(
  //       (m) => m.ReportPageModule
  //     ),
  // },
  {
    path: 'report-detail/:id',
    loadChildren: () =>
      import('./pages/report-detail/report-detail.module').then(
        (m) => m.ReportDetailPageModule
      ),
  },
  {
    path: 'report-form',
    loadChildren: () =>
      import('./pages/report-form/report-form.module').then(
        (m) => m.ReportFormPageModule
      ),
  },
  // Equipment ==================================================================
  {
    path: 'equipment',
    loadChildren: () =>
      import('./pages/tabs/equipment/equipment.module').then(
        (m) => m.EquipmentPageModule
      ),
  },
  {
    path: 'equipment-detail/:id',
    loadChildren: () =>
      import('./pages/equipment-detail/equipment-detail.module').then(
        (m) => m.EquipmentDetailPageModule
      ),
  },
  {
    path: 'equipment-form',
    loadChildren: () =>
      import('./pages/equipment-form/equipment-form.module').then(
        (m) => m.EquipmentFormPageModule
      ),
  },
  // Sertifikat ==================================================================
  {
    path: 'sertifikat-list',
    loadChildren: () =>
      import('./pages/sertifikat-list/sertifikat-list.module').then(
        (m) => m.SertifikatListPageModule
      ),
  },
  {
    path: 'sertifikat-detail/:id',
    loadChildren: () =>
      import('./pages/sertifikat-detail/sertifikat-detail.module').then(
        (m) => m.SertifikatDetailPageModule
      ),
  },
  {
    path: 'sertifikat-form',
    loadChildren: () =>
      import('./pages/sertifikat-form/sertifikat-form.module').then(
        (m) => m.SertifikatFormPageModule
      ),
  },
  // Register ==================================================================
  {
    path: 'register-equipment-list',
    loadChildren: () =>
      import(
        './pages/register-equipment-list/register-equipment-list.module'
      ).then((m) => m.RegisterEquipmentListPageModule),
  },
  {
    path: 'register-equipment-detail',
    loadChildren: () =>
      import(
        './pages/register-equipment-detail/register-equipment-detail.module'
      ).then((m) => m.RegisterEquipmentDetailPageModule),
  },
  // Default Page ==================================================================
  {
    path: 'profile',
    loadChildren: () =>
      import('./pages/profile/profile.module').then((m) => m.ProfilePageModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'my-action',
    loadChildren: () =>
      import('./pages/my-action/my-action.module').then(
        (m) => m.MyActionPageModule
      ),
  },
  {
    path: 'accident-detail/:id',
    loadChildren: () =>
      import('./pages/accident-detail/accident-detail.module').then(
        (m) => m.AccidentDetailPageModule
      ),
  },
  {
    path: 'accident-form',
    loadChildren: () =>
      import('./pages/accident-form/accident-form.module').then(
        (m) => m.AccidentFormPageModule
      ),
  },
  {
    path: 'map',
    loadChildren: () =>
      import('./components/map/map.module').then((m) => m.MapPageModule),
  },
  {
    path: 'accident-victim-detail/:id',
    loadChildren: () =>
      import(
        './pages/accident-victim-detail/accident-victim-detail.module'
      ).then((m) => m.AccidentVictimDetailPageModule),
  },
  {
    path: 'accident-victim-form',
    loadChildren: () =>
      import('./pages/accident-victim-form/accident-victim-form.module').then(
        (m) => m.AccidentVictimFormPageModule
      ),
  },
  {
    path: 'accident-property-damage-detail/:id',
    loadChildren: () =>
      import(
        './pages/accident-property-damage-detail/accident-property-damage-detail.module'
      ).then((m) => m.AccidentPropertyDamageDetailPageModule),
  },
  {
    path: 'accident-property-damage-form',
    loadChildren: () =>
      import(
        './pages/accident-property-damage-form/accident-property-damage-form.module'
      ).then((m) => m.AccidentPropertyDamageFormPageModule),
  },
  {
    path: 'accident-materials-damage-detail/:id',
    loadChildren: () =>
      import(
        './pages/accident-materials-damage-detail/accident-materials-damage-detail.module'
      ).then((m) => m.AccidentMaterialsDamageDetailPageModule),
  },
  {
    path: 'accident-materials-damage-form',
    loadChildren: () =>
      import(
        './pages/accident-materials-damage-form/accident-materials-damage-form.module'
      ).then((m) => m.AccidentMaterialsDamageFormPageModule),
  },
  {
    path: 'inspection-form',
    loadChildren: () =>
      import('./pages/inspection-form/inspection-form.module').then(
        (m) => m.InspectionFormPageModule
      ),
  },
  {
    path: 'inspection-detail/:id',
    loadChildren: () =>
      import('./pages/inspection-detail/inspection-detail.module').then(
        (m) => m.InspectionDetailPageModule
      ),
  },
  {
    path: 'inspection-form-detail',
    loadChildren: () =>
      import(
        './pages/inspection-form-detail/inspection-form-detail.module'
      ).then((m) => m.InspectionFormDetailPageModule),
  },
  {
    path: 'accident-investigation-teams-detail/:id',
    loadChildren: () =>
      import(
        './pages/accident-investigation-teams-detail/accident-investigation-teams-detail.module'
      ).then((m) => m.AccidentInvestigationTeamsDetailPageModule),
  },
  {
    path: 'accident-investigation-teams-form',
    loadChildren: () =>
      import(
        './pages/accident-investigation-teams-form/accident-investigation-teams-form.module'
      ).then((m) => m.AccidentInvestigationTeamsFormPageModule),
  },
  {
    path: 'accident-witnesses-detail/:id',
    loadChildren: () =>
      import(
        './pages/accident-witnesses-detail/accident-witnesses-detail.module'
      ).then((m) => m.AccidentWitnessesDetailPageModule),
  },
  {
    path: 'accident-witnesses-form',
    loadChildren: () =>
      import(
        './pages/accident-witnesses-form/accident-witnesses-form.module'
      ).then((m) => m.AccidentWitnessesFormPageModule),
  },
  {
    path: 'accident-corrective-preventive-detail/:id',
    loadChildren: () =>
      import(
        './pages/accident-corrective-preventive-detail/accident-corrective-preventive-detail.module'
      ).then((m) => m.AccidentCorrectivePreventiveDetailPageModule),
  },
  {
    path: 'accident-corrective-preventive-form',
    loadChildren: () =>
      import(
        './pages/accident-corrective-preventive-form/accident-corrective-preventive-form.module'
      ).then((m) => m.AccidentCorrectivePreventiveFormPageModule),
  },
  {
    path: 'recap-category-condition',
    loadChildren: () =>
      import(
        './pages/recap-category-condition/recap-category-condition.module'
      ).then((m) => m.RecapCategoryConditionPageModule),
  },
  {
    path: 'report',
    loadChildren: () =>
      import('./pages/tabs/report/report.module').then(
        (m) => m.ReportPageModule
      ),
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
