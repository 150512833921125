import { CapacitorConfig } from '@capacitor/cli';

const config: CapacitorConfig = {
  appId: 'id.sier.semar',
  // appId:'xyz.pttas.sierk3',
  appName: 'Safety Management SIER',
  webDir: 'www',
  bundledWebRuntime: false,
};

export default config;
