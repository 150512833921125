/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { Component } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';

import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { AuthService } from './services/auth.service';
import { Versioning } from './helper/versioning';
import { environment } from 'src/environments/environment';
import { NotificationHelper } from './helper/notification';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  production: boolean = environment.production;

  constructor(
    public plt: Platform,
    public navCtrl: NavController,
    private auth: AuthService,
    public version: Versioning
  ) {}

  async ngOnInit() {
    await this.plt.ready().then(async (res) => {
      // Cek User Authentication
      await this.auth.init();

      // If platform Mobile Device
      if (res !== 'dom') {
        await SplashScreen.hide();

        await StatusBar.setBackgroundColor({
          color: '#325aa2',
        });

        // Setting Status bar
        StatusBar.show();

        // Cek App Update
        if (this.production) {
          await this.version.cekUpdate();
        }
      }

      // bypass
      // await this.version.cekUpdate();
    });
  }
}
