/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { Injectable } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { NavController } from '@ionic/angular';

import OneSignal from 'onesignal-cordova-plugin';
import NotificationReceivedEvent from 'onesignal-cordova-plugin/dist/NotificationReceivedEvent';
import OSNotification from 'onesignal-cordova-plugin/dist/OSNotification';

@Injectable({
  providedIn: 'root',
})
export class NotificationHelper {
  oneSignalAppID = `254ed345-5f83-4d69-a0bd-142b21a05812`;
  restApiKey = `NmNiYzY3ZmQtYTUyNi00MzE5LTljYjgtYWVlMjhiNWI0MjFm`;
  deviceData: any;
  profile: any;
  constructor(private navCtrl: NavController) {
    this.profile = JSON.parse(localStorage.getItem('profile'));
  }

  init() {
    // Initialize the plugin
    OneSignal.setAppId('254ed345-5f83-4d69-a0bd-142b21a05812');

    // Set an initial value for notification received in the foreground
    OneSignal.setNotificationWillShowInForegroundHandler((jsonData) => {
      const data: NotificationReceivedEvent = jsonData;
      const notification: OSNotification = data.getNotification();
      this.onReceived(notification);
    });

    // Show an alert for each notification received
    OneSignal.setNotificationOpenedHandler((jsonData) => {
      //   console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
      this.onClicked(jsonData);
    });

    // Prompts the user for notification permissions.
    // eslint-disable-next-line max-len
    //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
    OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
      //   console.log('User accepted notifications: ' + accepted);
    });

    // get device info
    OneSignal.getDeviceState((deviceData) => {
      this.deviceData = deviceData;

      // OneSignal.setEmail(
      //   this.profile?.email,
      //   'abcdefg',
      //   function(response) {
      //     //Successfully set email
      //     //   console.log('success : ', response);
      //   },
      //   function(error) {
      //     //encountered an error setting email
      //     //   console.log('ERROR : ', error);
      //   }
      // );
    });

    this.setTag();
  }

  setTag() {
    console.log('before set tag...');

    const profile = JSON.parse(localStorage.getItem('profile'));
    delete profile.data_role_id;
    let tmp = {};
    tmp = {
      ...tmp,
      ...profile,
    };
    OneSignal.sendTags(tmp);
    console.log('after set tag...');
  }

  onReceived(data) {
    // data = JSON.parse(data);
    console.log('Notification Received : ', data);
  }

  onClicked(data) {
    // data = JSON.parse(data);
    console.log('Notification Clicked : ', data);
    if (data.notification) {
      const newData = {
        title: ``,
        description: '',
        data: null,
      };
      newData.title = data.notification.title;
      newData.description = data.notification.body;
      newData.data = data.notification.additionalData;

      console.log('Final Data Opened : ', newData);

      this.onDetail(newData.data);
    }
  }

  onDetail(data) {
    const id = data.id;
    const code = data.class_model_name;
    let url = ``;
    let type = ``;
    let area_id = null;
    switch (code) {
      case 'safety-patrol':
        url = `/report-detail/${id}`;
        type = `safety-patrol`;
        break;
      case 'management-walkthrough':
        url = `/report-detail/${id}`;
        type = `mwt`;
        break;
      case 'direct-findings':
        url = `/report-detail/${id}`;
        type = `directfindings`;
        break;
      case 'accident-reports':
        url = `/accident-detail/${id}`;
        break;
      case 'employee-certificates':
        url = `/sertifikat-detail/${id}`;
        type = `employee`;
        break;
      case 'equipment-certificates':
        url = `/sertifikat-detail/${id}`;
        type = `equipment`;
        break;
      case 'observation-schedules':
        url = `/report-form`;
        type = data?.finding_activity_code;
        area_id = data?.area_id;
        break;
      case 'equipments': // form inspeksi
        url = `/inspection-form`;
        break;
      case 'equipment-inspections': // inspeksi detail
        url = `/inspection-detail/${id}`;
        break;
      default:
        break;
    }
    if (!url) {
      return;
    }
    const param: NavigationExtras = {
      queryParams: {
        type,
      },
    };
    if (area_id) {
      param.queryParams.area_id = area_id;
    }

    if (type) {
      this.navCtrl.navigateForward([`${url}`], param);
    } else {
      this.navCtrl.navigateForward([`${url}`]);
    }
  }
}
