import { Pipe, PipeTransform } from '@angular/core';
import { format, parseISO } from 'date-fns';
import { id } from 'date-fns/locale';

@Pipe({
  name: 'customDate',
})
export class CustomDatePipe implements PipeTransform {
  transform(value: any = new Date(), ...args: string[]): unknown {
    let date = value;
    const type: string = args[0];
    if (date) {
      switch (type) {
        case 'year':
          return format(parseISO(date), 'yyyy', { locale: id });
          break;
        case 'month':
          return format(parseISO(date), 'MM-yyyy', { locale: id });
          break;
        case 'date':
          return format(parseISO(date), 'dd MMMM yyyy', { locale: id });
          break;
        case 'medium-date':
          return format(parseISO(date), 'dd MMM yyyy', { locale: id });
          break;
        case 'time':
          return format(parseISO(date), 'HH:mm:ss', { locale: id });
          break;
        case 'datetime':
          return format(parseISO(date), 'dd-MM-yyyy HH:mm:ss', {
            locale: id,
          });
          break;
        case 'fulldate':
          return format(parseISO(date), 'EEEE, dd MMMM yyyy, HH:mm', {
            locale: id,
          });
          break;
        default:
          return format(parseISO(date), type, { locale: id });
          break;
      }
    } else {
      date = new Date();
      switch (type) {
        case 'year':
          return format(date, 'yyyy', { locale: id });
          break;
        case 'month':
          return format(date, 'MM-yyyy', { locale: id });
          break;
        case 'time':
          return format(date, 'HH:mm:ss', { locale: id });
          break;
        case 'datetime':
          return format(date, 'dd-MM-yyyy HH:mm:ss', {
            locale: id,
          });
          break;
        default:
          return format(date, 'dd-MM-yyyy', { locale: id });
          break;
      }
    }
  }
}
